import React from 'react';
import { Container } from 'react-bootstrap';

const Progress = () => {

    return (
        <Container>
            <h1>Progress</h1>
        </Container>
    );

}
export default Progress;